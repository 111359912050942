import React from 'react';
export const HOSTNAME = process.env.REACT_APP_API_BASE_URL || "";

const NoBackendConnection: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f8f9fa',
      color: '#333',
      padding: '20px',
      textAlign: 'center'
    }}>
      <h1 style={{ fontSize: '2.5em', marginBottom: '0.5em' }}>Connection Issue</h1>
      <h3 style={{ fontSize: '1.5em', marginBottom: '1em' }}>We are unable to connect to the backend server.</h3>
      <p style={{ fontSize: '1.2em' }}>
        Please check if your corporate firewall is blocking requests to the API at <b>{`${HOSTNAME}api/v1`}</b>
      </p>
    </div>
  );
};

export default NoBackendConnection;
