import { api } from "services/api.service";
import { AuthenticationContext } from "./useAuthentication";
import { createContext, useContext, useEffect, useState } from "react";
import { Role } from "types/role";
import TokenService from "services/token.service";

type User = {
  id: number;
  uuid: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  role?: Role;
};

type CurrentUserContextValue = {
  currentUser?: User;
  role?: Role;
};

export const CurrentUserContext = createContext<CurrentUserContextValue>({
  currentUser: undefined,
  role: undefined,
});

export const CurrentUserProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const { loggedIn, setLoggedIn } = useContext(AuthenticationContext);

  useEffect(() => {
    if (!currentUser && loggedIn && TokenService.get()) {
      getCurrentUser();
    }
  }, [loggedIn]);

  const getCurrentUser = () => {
    api
      .get("/current_user")
      .then((response) => {
        setLoggedIn(true);
        setCurrentUser(response.data.user);
      })
      .catch(() => {
        // setLoggedIn(false);
        // TokenService.remove();
        // window.location.href = "/login";
      });
  };

  return (
    <CurrentUserContext.Provider
      value={{ currentUser: currentUser, role: currentUser?.role }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

// Custom hook to use the CurrentUserContext
export const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};
