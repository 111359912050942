import AppLayout from "components/AppLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import Assets from "./Assets";
import Login from "./Login";
import Pickups from "./Pickups";
import CompanySettings from "./CompanySettings";
import UserManagement from "./CompanySettings/Users/UserManagement";
import Home from "./Home";
import NewUser from "./CompanySettings/Users/New";
import Users from "./CompanySettings/Users";
import EditUser from "./CompanySettings/Users/Edit";
import Support from "./Support";
import MagicLinkSignin from "./MagicLinkSignin";
import NewPickup from "./Pickups/PickupNew";
import EditPickup from "./Pickups/PickupEdit";
import NoBackendConnection from "components/NoBackendConnection";

export default function Pages() {
  return (
    <Routes>
      <Route path="/support/:token" element={<Support />} />
      <Route path="/no-backend-connection" element={<NoBackendConnection />} />
      <Route path="/magic-link-signin/:token" element={<MagicLinkSignin />} />
      <Route
        path="/login"
        element={
          <AppLayout>
            <Login />
          </AppLayout>
        }
      />
      <Route
        path="/pickups"
        element={
          <AppLayout>
            <Pickups />
          </AppLayout>
        }
      />
      <Route
        path="/pickups/new"
        element={
          <AppLayout container>
            <NewPickup />
          </AppLayout>
        }
      />
      <Route
        path="/pickups/:pickupUuid/edit"
        element={
          <AppLayout container>
            <EditPickup />
          </AppLayout>
        }
      />
      <Route
        path="/assets"
        element={
          <AppLayout>
            <Assets />
          </AppLayout>
        }
      />
      <Route
        path="/company-settings"
        element={
          <AppLayout noPadding>
            <CompanySettings />
          </AppLayout>
        }
      >
        <Route
          index
          element={<Navigate to="/company-settings/users" replace />}
        />
        <Route path="users" element={<Users />}>
          <Route index element={<UserManagement />} />
          <Route path="new" element={<NewUser />} />
          <Route path=":uuid/edit" element={<EditUser />} />
        </Route>
      </Route>

      <Route
        path="/*"
        element={
          <AppLayout>
            <Pickups />
          </AppLayout>
        }
      ></Route>
    </Routes>
  );
}
